.logo-box {
    overflow: hidden;
}

.logo-img {
    width: 1.5rem;
    height: 1.5rem;
}

.radmin-navbar {
    height: 3rem;
    background-color: black;
}

.radmin-content {
    overflow: auto;
    height: calc(100% - 3rem);
}

.login-modal-body {
    background-color: #212529;
    color: white;
}

.login-button {
    border: none;
    background: none;
    color: #f49120;
}

.login-modal-button {
    border-radius: 5px;
}