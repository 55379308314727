.calendar-title {
    color: #f49120;
}

.calendar-error {
    color: red;
}

.cal {
    background-color: white !important;
}

.notify-logo {
    width: 25px;
    height: 25px;
}

/* ----------Dark Mode:---------- */
.dark {
    background: linear-gradient(to bottom, #2d3035 0%, #202528 100%);
    color: white;
}

/* ----------Light Mode:---------- */
.light {
    background: linear-gradient(to bottom, #f9f9f9 0%, #e4e4e5 100%);
}