.pi-card {
    width: fit-content;
}

.field-card {
    width: fit-content;
    background-color: #1a2633 !important;
}

.league-title {
    color: #f49120;
}

a:link, a:visited {
    color: #f49120 !important;
    text-decoration: none !important;
}

a:hover, a:active {
    color: #f49120 !important;
    text-decoration: none !important;
}

.material-icons.run_circle {
    color: green;
}

.material-icons.error_outline {
    color: red;
}

/* Rules for sizing the icon. */
.material-icons.md-18 {
    font-size: 18px;
}

.material-icons.md-24 {
    font-size: 24px;
}

.material-icons.md-36 {
    font-size: 36px;
}

.material-icons.md-48 {
    font-size: 48px;
}


.iteminvisible {
    flex: 0 1 auto;
    width: 36px;
    visibility: hidden;
}

.tooltip {
    top: 0;
    left: 0;
    right: 0;
}

.error-message {
    color: red;
}

.inline-icon {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.fa-icon {
    color: #f49120;
}

.dashboard-table td {
    vertical-align: middle;
}